import axios from '@/axios.js'

export default {
    getAppleAccounts: async (params, page = 1) => {
        try {
            const response = await axios.adminapi.get(`v4/apps/apple-accounts`,{
                params: {
                    ...params,
                    page: page
                }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },

    getAppleAccount: async (uuid) => {
        const response = await axios.adminapi.get(`v4/apps/apple-accounts/${uuid}`)
        return response.data.object
    },

    updateAppleAccount: async (uuid, params) => {
        let response = await axios.adminapi.put(`v4/apps/apple-accounts/${uuid}`, params)
        return response.data.object
    },

    createAppleAccount: async (uuid, params) => {
        let response = await axios.adminapi.post(`v4/apps/apple-accounts/${uuid}`, params)
        return response.data.object
    },

    deleteAppleAccount: async (uuid) => {
        const response = await axios.adminapi.delete(`v4/apps/apple-accounts/${uuid}`)
        return response.data.object
    },
}
