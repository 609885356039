<template>
  <div class="mt-4">
    <AppleAccountForm v-model="appleAccount"
      @cancel="$router.push({name: 'admin-apple-accounts'})"
      @save="save"
    />
  </div>
</template>

<script>

import store from "@/modules/Shared/Store/store";
import loader from "@/modules/Shared/Mixins/loader";
import AppleAccountService from "@/modules/Admin/Services/AppleAccountService";
import AppleAccountForm from "@/modules/Admin/Components/AppleAccounts/AppleAccountForm.vue";

export default {
  name: "EditAppleAccountPage",
  components: { AppleAccountForm },
  mixins: [loader],

  data() {
    return {
      appleAccount: null,
      new: true
    }
  },

  async beforeRouteEnter (to, from, next) {
      try {
        const appleAccount = await AppleAccountService.getAppleAccount(to.params.uuid);
        await store.dispatch('setRouteElement', appleAccount)
        next(vm => {
            vm.appleAccount = appleAccount
            vm.new = false
        })
      } catch (error) {
          next(vm => {
              vm.appleAccount = {
                  uuid: to.params.uuid,
                  fastlane_apple_application_specific_password: "aodt-cvwi-ziuh-unmy",
                  issuer_id: "",
                  itc_team_id: "",
                  key_id: "",
                  team_id: "",
                  team_name: "",
                  type: "company",
                  xcode_apple_id: "iosdevelopers@skitudeservices.com",
              }
              vm.new = true
          })
      }
  },

  methods: {
    save() {
      this.loadAndNotify(async () => {
        if (this.new) {
          await AppleAccountService.createAppleAccount(this.appleAccount.uuid, this.appleAccount);
        } else {
          await AppleAccountService.updateAppleAccount(this.appleAccount.uuid, this.appleAccount);
        }
      })
    }
  }
};
</script>
