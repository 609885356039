<template>
    <div v-if="value" class="vx-card sm:w-full md:w-2/5 p-8">
        <vs-select
            class="border-grey-light pb-2 w-full"
            v-model="internalAppleAccount.type"
            required
            :label="$t('admin.apple_accounts.type') + ' *'"
        >
            <vs-select-item key="company" value="company" text="Company"/>
            <vs-select-item key="personal" value="personal" text="Personal"/>
        </vs-select>

        <vs-input
            class="border-grey-light w-full"
            type="text"
            v-model="internalAppleAccount.team_name"
            :label="$t('admin.apple_accounts.team_name') + ' *'"
            :danger="internalAppleAccount.team_name === ''"
            placeholder="Spotlio"
            required
        />

        <vs-input
            class="border-grey-light w-full mt-4"
            type="text"
            v-model="internalAppleAccount.team_id"
            :label="$t('admin.apple_accounts.team_id') + ' *'"
            :danger="internalAppleAccount.team_id === ''"
            placeholder="AS2KY3ADIC"
            required
        />

        <vs-input
            class="border-grey-light w-full"
            type="text"
            v-model="internalAppleAccount.fastlane_apple_application_specific_password"
            :label="$t('admin.apple_accounts.fastlane_apple_application_specific_password') + ' *'"
            :danger="internalAppleAccount.fastlane_apple_application_specific_password === ''"
            placeholder="aaaa-aaaa-cccc-aaaa"
            required
        />

        <vs-input
            class="border-grey-light w-full mt-4"
            type="text"
            v-model="internalAppleAccount.issuer_id"
            :label="$t('admin.apple_accounts.issuer_id') + ' *'"
            :danger="internalAppleAccount.issuer_id === ''"
            placeholder="62587911-870d-4521-8fe1-bf72f7cf251a"
            required
        />

        <vs-input
            class="border-grey-light w-full mt-4"
            type="number"
            v-model="internalAppleAccount.itc_team_id"
            :label="$t('admin.apple_accounts.itc_team_id')"
            placeholder="103152323"
        />

        <vs-input
            class="border-grey-light w-full mt-4"
            type="text"
            v-model="internalAppleAccount.key_id"
            :label="$t('admin.apple_accounts.key_id') + ' *'"
            :danger="internalAppleAccount.key_id === ''"
            placeholder="AS21D23T25"
            required
        />

        <vs-input
            class="border-grey-light w-full mt-4"
            type="email"
            v-model="internalAppleAccount.xcode_apple_id"
            :label="$t('admin.apple_accounts.xcode_apple_id') + ' *'"
            :danger="internalAppleAccount.xcode_apple_id === ''"
            placeholder="info@spotlio.com"
            required
        />

        <div class="flex flex-row gap-4 mt-12">
            <button class="sk-btn sk-btn-primary rounded-lg btn-border" @click="$emit('cancel')">
                {{ $t('apps.modals.cancel') }}
            </button>
            <button class="sk-btn sk-btn-primary rounded-lg" :disabled="!isValidForm()" @click="$emit('save')">
                {{ $t('Save') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppleAccountForm",
    components: {},
    mixins: [],
    props: [ 'value' ],
    data() {
        return {
            internalAppleAccount: {}
        }
    },
    watch: {
        value() {
            this.internalAppleAccount = JSON.parse(JSON.stringify(this.value))
        }
    },
    methods: {
        isValidForm() {
            return this.internalAppleAccount.team_name !== '' &&
                this.internalAppleAccount.team_id !== '' &&
                this.internalAppleAccount.fastlane_apple_application_specific_password !== '' &&
                this.internalAppleAccount.issuer_id !== '' &&
                this.internalAppleAccount.key_id !== '' &&
                this.internalAppleAccount.xcode_apple_id !== ''
        }
    }
};
</script>
